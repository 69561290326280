export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Meta description"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:image:alt","content":"Alt text for image"},{"property":"og:title","content":"Codeflyers - First we solve the problem. Then we write the code"},{"property":"og:type","content":"article"},{"property":"og:image","content":"https://s3-eu-west-1.amazonaws.com/landingi-editor-uploads/mmB78s4u/CF_META_SM.png"},{"property":"og:url","content":"https://codeflyers.com/"},{"property":"og:description","content":"We are digitalization consulting and software development partners, with a proven footprint in Norway."},{"property":"og:site_name","content":"Codeflyers"},{"name":"robots","content":"noindex, nofollow"}],"link":[],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=undefined","async":true},{"innerHTML":"window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)};gtag('js', new Date());gtag('config', undefined);"},{}],"noscript":[],"title":"Home","htmlAttrs":{"lang":"en"},"titleTemplate":"Code Flyers - %s"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"